import { Controller } from '@hotwired/stimulus';

var $ = require('jquery');

// Connects to data-controller='form-submit'
export default class extends Controller {
  static targets = ['form']

  submit(evt) {
    const target = evt.target
    const dataset = target.dataset
    const urlPath = dataset.urlPath

    this.formTarget.action = urlPath
    this.formTarget.submit()
  }
}
