import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="zip-search"
export default class extends Controller {
  static targets = ['zipcode', 'prefecture', 'city', 'street']

  search() {
    const url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
    const zipcode = this.zipcodeTarget.value

    fetch(url + zipcode, {
        method: "GET",
        mode: "cors"
      })
        .then(response => response.json())
        .then(data => {
          const body = data.results[0]
          this.prefectureTarget.value = body.address1
          this.cityTarget.value = body.address2
          this.streetTarget.value = body.address3
        });
  }
}