import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainImage", "thumbnail"]

  changeImage(event) {
    const newImageUrl = event.currentTarget.dataset.largeImageUrl
    this.mainImageTarget.src = newImageUrl
  }
}
