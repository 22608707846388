import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller='modal'
export default class extends Controller {
  static targets = ['container']

  connect() {
    this.modal = new Modal(this.containerTarget)
    this.modal.hide()
  }

  open(event) {
    this.modal.show()
  }

  close(event) {
    this.modal.hide()
  }
}