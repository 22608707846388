import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-terms"
export default class extends Controller {
  static targets = ['terms', 'checkbox']
  static classes = [ "active" ]

  scroll() {
    //全長を取得
    const scrollHeight = this.termsTarget.scrollHeight;
    //スクロールした高さを取得
    const scrollPosition = this.termsTarget.clientHeight + this.termsTarget.scrollTop;
    //スクロールの位置が下部何%の範囲に来た場合の処理(例:3%)
    if ((scrollHeight - scrollPosition) / scrollHeight <= 0.03) {
      this.checkboxTarget.removeAttribute('disabled')
      // this.checkboxTarget.parent.classList.add(this.activeClass)
      // this.checkboxTarget.parent.parent.classList.add(this.activeClass)
    }
  }
}