import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='menu-disclosure'
export default class extends Controller {
  static targets = ['open', 'close', 'disclosure'];
  static classes = ['open'];

  connect() {
    this.disclosureHide()
  }

  open(evt) {
    this.disclosureShow()
  }

  close(evt) {
    this.disclosureHide()
  }

  disclosureShow() {
    if (this.hasDisclosureTarget) {
      this.disclosureTarget.classList.add(this.openClass)
    }
    if (this.hasOpenTarget) {
      this.openTarget.classList.remove(this.openClass)
    }
    if (this.hasCloseTarget) {
      this.closeTarget.classList.add(this.openClass)
    }
  }

  disclosureHide() {
    if (this.hasDisclosureTarget) {
      this.disclosureTarget.classList.remove(this.openClass)
    }
    if (this.hasOpenTarget) {
      this.openTarget.classList.add(this.openClass)
    }
    if (this.hasCloseTarget) {
      this.closeTarget.classList.remove(this.openClass)
    }
  }
}
