import { application } from './application';

import Select2Controller from "./select2_controller"
application.register("select2", Select2Controller)

import ModalController from "./modal_controller"
application.register("modal", ModalController)

import ZipSearchController from "./zip_search_controller"
application.register("zip-search", ZipSearchController)

import CheckTermsController from "./check_terms_controller"
application.register("check-terms", CheckTermsController)

import MenuDisclosureController from "./menu_disclosure_controller"
application.register("menu-disclosure", MenuDisclosureController)

import FormSubmitController from './form-submit_controller'
application.register('form-submit', FormSubmitController)

import CheckboxSelectAll from "./checkbox-select-all_controller"
application.register("checkbox-select-all", CheckboxSelectAll)

import ImageSwitch from "./image-switch_controller"
application.register("image-switch", ImageSwitch)
