// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import '../src/controllers';

import '@fortawesome/fontawesome-free/css/all.min';
import 'admin-lte/dist/css/adminlte.min';

var $ = require('jquery');
var jQuery = $;

// require('@fortawesome/fontawesome-free/js/all.min');
import 'admin-lte';
import 'bootstrap/dist/js/bootstrap';

